import { isDownSize } from '@/@core/utils/responsive-utils';

export default {
  name: 'GridEnabledMixin',
  data() {
    return {
      isSmallDevice: true,
    };
  },
  computed: {
    isLandingSkin() {
      return this.$store.state.appConfig.layout.skin === 'landing';
    },
    isGridDisabled() {
      return this.isLandingSkin || this.isSmallDevice;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmallDevice = isDownSize('md');
    },
  },
};
