const sizes = Object.freeze({
  // Small devices (landscape phones, 576px and up)
  sm: 576,
  // Medium devices (tablets, 768px and up)
  md: 768,
  // Large devices (desktops, 992px and up)
  lg: 992,
  // X-Large devices (large desktops, 1200px and up)
  xlg: 1200,
});

export function isUpSize(size) {
  if (size === 'xs') {
    return window.innerWidth < sizes.sm;
  }

  const width = sizes[size];

  if (!width) {
    throw new Error('Invalid size');
  }

  return window.innerWidth >= width;
}

export function isDownSize(size) {
  switch (size) {
    case 'xs':
      return window.innerWidth <= sizes.sm - 0.2;
    case 'sm':
      return window.innerWidth <= sizes.md - 0.2;
    case 'md':
      return window.innerWidth <= sizes.lg - 0.2;
    case 'lg':
      return window.innerWidth <= sizes.xlg - 0.2;
    case 'xlg':
      return true;
    default:
      throw new Error('Invalid size');
  }
}
