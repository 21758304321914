import Service from '@/config/service-identifiers';
import $services from '@/config/container';

/**
 * Returns the metadata of an array of urls
 * @param {String[]} urls
 * @returns { previews: { [url: string]: LinkPreview } }
 */
export function toAbsoluteURL(url) {
  if (url.startsWith('http')) {
    return url;
  }
  if (url.startsWith('//')) {
    return `https:${url}`;
  }
  return `https://${url}`;
}

export async function getLinksPreview(urls) {
  const validURLs = urls.map((url) => toAbsoluteURL(url));

  const response = await $services[Service.BackendClient].get('getLinkPreview', { params: { urls: validURLs } });

  return response.data;
}

/**
 * Returns the metadata of the given url
 * @param {String[]} urls
 * @returns LinkPreview
 */
export async function getLinkPreview(url) {
  const response = await getLinksPreview([url]);

  return Object.values(response.previews)[0];
}

export function getUri({ url, params, excludeBase }) {
  // Fix due to a axios bug.
  // @see https://github.com/axios/axios/issues/2468
  const fullUrl = (!excludeBase ? $services[Service.BackendClient].getBaseURL() : '')
    + (!url.startsWith('/') ? '/' : '') + url;

  return $services[Service.BackendClient].getUri({ url: fullUrl, params });
}

/**
 * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
 *
 * @param  {String} base64String
 * @return {Uint8Array}
 */
export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData?.charCodeAt(i);
  }
  return outputArray;
}
